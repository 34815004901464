import { useQuery } from "@apollo/client";
import { User } from "src/graphql/query/user";

export const useUser = () => {
  if (typeof localStorage === "undefined" || !localStorage?.getItem("user-token")) {
    return { data: null, loading: false, error: null };
  }
  const { data, loading, error } = useQuery(User);

  if (error) {
    return { error };
  }
  return { data, loading, error };
};
