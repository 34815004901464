export const removeRepeated = (categories) => {
    return Array.from(new Set(categories.map(cat => cat.name))).map(name => {return categories.find(cat => cat.name === name)})
}
/***
 * Array sorted by character wise
 * @param arr
 */
 export const arrSortCategoriesByLabel = arr => {
    return arr.sort((a, b) => {
        const nameA = a.label.toLowerCase();
        const nameB = b.label.toLowerCase();
        if (nameA < nameB) {
            return -1
        } else if (nameA > nameB) {
            return 1
        }
        return 0;
    });
}