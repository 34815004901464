import { gql } from "@apollo/client";

export const User = gql`
query GetUser {
    me {
        name
        lastName
        email
        role { 
            name
        }
    }
}`