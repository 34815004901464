import Link from "next/link";
import data from "@data/account-menu.json";
import { useMemo } from "react";

const Account = ({
  className,
  isHidden,
  openHandler,
  closeHandler,
  color,
  user,
}) => {
  const username = useMemo(() => {
    if (user?.data) {
      return user.data?.me?.email.split("@")[0];
    } else {
      return "";
    }
  }, [user]);

  const items = [...data];

  if (!user?.data) {
    items.push(
      ...[
        {
          id: 5,
          iconClass: "icon-f-76",
          text: "Ingresar",
          link: "/account/login",
        },
        {
          id: 6,
          iconClass: "icon-f-94",
          text: "Registrate",
          link: "/account/register",
        },
      ]
    );
  }

  return (
    <div className={`tt-parent-box ${className}`}>
      <div
        className={`tt-account tt-dropdown-obj ${!isHidden ? "active" : null}`}
      >
        <button
          className="tt-dropdown-toggle"
          data-id="account"
          onClick={openHandler}
        >
          {!user?.loading && user?.data ? (
            <p style={{ padding: 0, margin: 0, marginLeft: 12, color: color }}>{username}</p>
          ) : (
            <i className="icon-f-94" style={{ color }} />
          )}
        </button>
        <div className="tt-dropdown-menu">
          <div className="tt-mobile-add">
            <button
              className="tt-close"
              data-id="account"
              onClick={closeHandler}
            >
              Cerrar
            </button>
          </div>
          <div className="tt-dropdown-inner">
            <ul>
              {items.map((item) => (
                <li key={item.id}>
                  <Link href={item.link}>
                    <a>
                      <i className={item.iconClass} />
                      {item.text}
                    </a>
                  </Link>
                </li>
              ))}
              {user.data && (
                <li
                  key={12}
                  onClick={() => {
                    localStorage.removeItem("user-token");
                    window.location.reload();
                  }}
                >
                  <a style={{ cursor: 'pointer' }}>
                    <i className={"icon-f-94"} />
                    Salir
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
